// Here you can add other styles
.hidden {
  display: none !important;
}
.sidebar-nav{
  margin-top: 15px;
  padding: 0 20px;
}
.wrapper{
/*background-color: #EBEBEB;*/
}
.sidebar-nav .nav-link{
  border-radius: .5rem;
  font-weight: bold;
}
.sidebar-brand{
  margin-top: 8px;
  flex-direction: column;
}
.sidebar-brand p{
  margin-top: 5px;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1rem;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 2px;
}
.text-right {
  text-align: right;
}

.bg-login {
  background-color: #112a41;
  color: #dadcea;
  line-height: 1.7rem;
}

.text-background {
  position: relative;
  z-index: 1;
}

.text-background span {
  background-color: #FFF;
  padding: 0 20px;
}

.text-background:before {
  border-top: 2px solid #dfdfdf;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  z-index: -1;
}
.validation-error input{
  border-color: #e55353 !important;
}
.validation-error fieldset, .basic-single.is-invalid .select2-selection__control {
  border-color: #e55353 !important;
}

.validation-errors {
  border-top: 1px solid #e55353;
  border-bottom: 1px solid #e55353;
  margin-bottom: 15px;
}

.validation-errors ul {
  padding-left: 6px;
  margin-bottom: 0;
}

/*FORM*/
.validation-errors .invalid-feedback {
  display: block;
  font-size: 1rem;
  padding: 3px 0 3px 0;
}

.astrics {
  margin-left: 4px;

}

.mr-5{
  margin-right: 5px !important;;
}
.mr-10{
  margin-right: 10px !important;;
}
.mr-15{
  margin-right: 15px !important;;
}
.mr-20{
  margin-right: 20px !important;;
}
.ml-5{
  margin-left: 5px !important;
}
.ml-10{
  margin-left: 10px !important;
}
.ml-15{
  margin-left: 15px !important;
}
.ml-20{
  margin-left: 20px !important;
}
.pl-5{
  padding-left: 5px !important;
}
.pl-10{
  padding-left: 10px !important;
}
.pl-15{
  padding-left: 15px !important;
}
.mt-5{
  margin-top: 5px !important;;
}
.mt-10{
  margin-top: 10px !important;;
}
.mt-15{
  margin-top: 15px !important;;
}
.mt-20{
  margin-top: 20px !important;;
}
.user-letters{
  background: #112a41;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  width: 28px;
  height: 28px;
}
.user-letters-lg .user-letters{
  width: 38px;
  height: 38px;
  font-size: 13px;
}

.shipment-card-text{
  font-size: .75rem;
}

.accordion-button:not(.collapsed){
  background-color: #f2f2f2 !important;
  color: #333333 !important;
}

.dataTable-wrapper.dataTable-wrapper-no-shadow .MuiPaper-root,
.dataTable-wrapper-no-shadow.dataTable-wrapper-regular, .no-shadow, .no-shadow:hover {
  box-shadow: none;
}

.dataTable-wrapper .dataTable th{
  font-weight: bold;
  color: #374253;
  background-color:  #d5d9dd;
  white-space: nowrap;
}
.dataTable-wrapper .dataTable tr:hover td{
  background-color: #F7F9FB;
}
.dataTable-wrapper .dataTable tr.row-expanded td,.dataTable-wrapper .dataTable tr.row-expanded .card{
  background-color: #f2f2f2;
}
.dataTable-wrapper .dataTable tr.row-expanded:hover td{
  background-color: #f2f2f2;
}
.dataTable-wrapper .MuiTablePagination-root p{
  margin-bottom:0;
}
.dataTable-wrapper .dataTable{
  /*margin-bottom: 90px;*/
  position: relative;
  z-index: 99;
}
.dataTable-wrapper .dataTable thead th button{
  font-weight: bold;
  font-style: normal;
  text-transform: inherit;
  background-color: transparent;
  padding: 2px;
}
.dataTable-wrapper.sm-padding .table td{
  padding: 0.15rem 0.3rem !important;
}
.dataTable-wrapper.sm-padding .table td .MuiCheckbox-root{
  padding: 3px;
}
.dataTable-wrapper.xl-padding .table td{
  padding: 15px !important;
}

.dataTable-wrapper.font-xs table td {
  font-size: 0.75rem !important;
}
.dataTable-wrapper table td {
  border-bottom: 1px solid #b3b3b3;
  vertical-align: middle;
  white-space: nowrap;
}
.dataTable-wrapper.no-padding table td{
  padding: 0rem 0.5rem !important;
}
.dataTable-wrapper.md-padding table td{
  padding: 0.5rem 0.5rem !important;
}
.MuiTableFooter-root .MuiTablePagination-toolbar{
  overflow: hidden;
}
[class*="MUIDataTable-responsiveBase"]{
  overflow: inherit !important;
}

.dataTable-wrapper.hide-expandable-button #expandable-button,
.dataTable-wrapper.hide-pagination table.MuiTable-root tfoot{
  display: none;
}

.dataTable-wrapper.mb-0 .dataTable {
  margin-bottom: 0;
}
.media {
  align-items: flex-start;
  display: flex;
}
.media-body {
  flex: 1 1;
}// Here you can add other styles
.card .table td a, .btn-link.link-text{
  color:#48739d;
}
.bg-badge-primary{
  background-color: #5d92f4 !important;
}
.bg-badge-secondary{
  background-color: #677080 !important;
}
.bg-badge-info{
  background-color: #00d0bd !important;
}

.bg-badge-dark{
  background-color: #464d69 !important;
}
.bg-badge-success{
  background-color: #00d014 !important;
}
.messages h6{
  font-size: .875rem;
}

.header-right{
  display: flex;
  position: relative;
}
.language-switcher{
  margin-right: 115px;
}

.address.right{
  text-align: right;
}
.rfq-cell label{
  display: none;
}
@media (max-width: 576px) {
  .header-right{
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .language-switcher{
    margin-right: 50px;
  }
  .shipment-card-addresses {
    display: flex;
    flex-direction: column;
  }
  .address.right{
    text-align: left;
    margin-top: 30px;
  }
  .address .title{
    display: block !important;
  }
  .shipment-item-extra{
    padding-left: 0 !important;
  }

  .shipment-item-AccordionSummary{
    flex-direction: column !important;
    align-items: flex-start !important;
    flex-wrap: nowrap !important;
  }
  .shipment-item-extra div{
    flex-direction: column;
  }
  .rfq-cell{
    padding: 0 15px;
  }
  .rfq-prices thead{
    display: none;
  }
  .rfq-prices td{
    display: block;
    box-sizing: border-box;
    padding: 5px 0;
  }
  .rfq-prices tr:nth-child(even) {
    background-color: #f6f6f6;
  }
  .rfq-cell label{
    display: block;
    padding-bottom: 5px;
  }
}
